
    .container {
        display: flex;
        justify-content: center;
        padding: 2rem 0;
      }
      
      @media (min-width: 1024px) {
        .container {
          padding: 0;
        }
      }
      
      .image-wrapper {
        border: 2px dashed #d1d5db; /* Tailwind's border-gray-300 */
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      .image-selected {
        padding: 0;
        height: 8rem; 
        width: 8rem; 
      }
      
      .image-unselected {
        padding: 0.5rem; 
        height: 9rem; 
        width: 9rem; 
        border: 2px dashed #d1d5db; 
      }
      
      .label {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 8rem; 
        height: 8rem; 
        border: 2px solid #d1d5db; 
        border-radius: 50%;
        cursor: pointer;
        background-color: #d1d2d4; 
      }
      
      .label:hover {
        background-color: #f3f4f6; 
      }
      
      .text {
        margin-bottom: 0.5rem; 
        font-size: 0.875rem; 
        font-weight: 600; 
      }
      
      .upload-icon {
        padding-top: 1.25rem; 
        padding-bottom: 1.5rem; 
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      
      .button {
        position: absolute;
        top: 12%;
        border-radius: 50%;
        padding: 0.5rem;
        background-color: #16a34a; 
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid white; 
      }
.hidden{
  display: none;
}