ul[aria-label="Pagination"] {
    display: flex;
    justify-content: space-between; /* Space out previous and next buttons */
    align-items: center;
    list-style: none;
    padding: 0;
  }
  
  ul[aria-label="Pagination"] li {
    display: inline-block;
    margin: 0 5px;
  }
  
  ul[aria-label="Pagination"] .previous {
    margin-right: auto; /* Pushes previous button to the far left */
  }
  
  ul[aria-label="Pagination"] .next {
    margin-left: auto; /* Pushes next button to the far right */
  }
  
  ul[aria-label="Pagination"] .active a {
    font-weight: bold;
  }
  
  ul[aria-label="Pagination"] a {
    text-decoration: none;
    transition: transform 0.3s;
    cursor: pointer;
    user-select: none;
  }

  /* Styling for pagination container */
.pagination-container {
   width: 50%;
   margin: auto;
  }
  
  /* Styling for each pagination item */
  .pagination-item {
    display: inline-block;
    margin-right: 0.25rem; /* 4px of space between items */
  }
  
  /* Styling for the pagination links */
  .pagination-link {
    display: block;
    padding: 0.5rem 1rem; /* Padding for each page number */
    border: 1px solid #e5e7eb; /* Light border */
    border-radius: 9999px; /* Fully rounded for the pill shape */
    background-color: #f3f4f6; /* Light background */
    text-align: center;
    color: #1f2937; /* Dark text color */
    text-decoration: none;
  }
  
  /* Styling for active page */
  .pagination-link.active {
    color: #ffffff;
    background-color: #4aa181; /* Tailwind's primary blue color */
    border-color: #4aa181;
    font-weight: bold;
  }
  
  /* Styling for hovered page link */
  .pagination-link:hover {
    background-color: #4aa181;
    color: #ffffff;
    border-color: #4aa181;
  }
  
  /* Styling for the 'Next' and 'Previous' buttons */
  .pagination-button {
    padding: 0.5rem 1rem;
    font-size: 1.125rem; /* Larger text for the button */
    border-radius: 0.5rem; /* Rounded corners */
    color: #6b7280; /* Gray text */
    border: 1px solid #e5e7eb; /* Light border */
    background-color: #f3f4f6;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .pagination-button:hover {
    background-color: #4aa181;
    color: #ffffff;
    border-color: #4aa181;
  }
  
  /* Styling for the break label (dots) */
  .pagination-break {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .pagination-break .dot {
    background-color: #6b7280; /* Tailwind's gray-500 color */
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    margin-right: 0.25rem; /* 4px gap between dots */
  }
  
  @media screen and (max-width:1080px)  {
      /* Styling for pagination container */
.pagination-container {
    width: 100%;
   }
  }