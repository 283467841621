.btn-save {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4aa181 !important;
    border-radius: 10px !important;
    font-size: 12px !important;
    color: #ffffff !important;
    padding: 0.5rem 1.1rem !important;
    margin-right: 0.5rem;
    height: 42px !important;
    width: 100px !important;
    font-weight: 600 !important;
    cursor: pointer;
    &:disabled{
      opacity: 60%;
    }
  }
  
  .input {
    border: 1px solid #ced4da;
  }
  
  .color-green {
    color: #4aa181;
  }