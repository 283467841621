::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
::-webkit-scrollbar-thumb {
  background-color: #44444f;
  border-radius: 1rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.header {
  /* width: 79%; */
  padding: 1.5rem 1.8rem;
  justify-content: space-between;

  min-height: calc(100vh - 10px);
}

.header_open {
  margin-left: 16.5rem;
}
.header_close {
  margin-left: 80px;
}

.header > div {
  position: relative;
}

@media (min-width: 768px) {
  .header {
    margin-top: -80px;
    padding-top: 0.75rem;
  }
  .header > div {
    padding: 0;
  }
}

.header > div .demo-button-wrapper {
  position: absolute;
  right: 0;
}

@media (max-width: 660px) {
  .header > div .demo-button-wrapper {
    right: 0;
  }
}

.dropdown-column {
  background-color: #ebebeb;
  border-radius: 8px;
  position: fixed;
  /* top: -8px;
  left: 5px; */
  margin-left: 16px;
  width: max-content;
  z-index: 999;
  > div {
    padding: 8px;
    border-radius: 8px;
    &:hover {
      cursor: pointer;
      background-color: #b1b1b1;
    }
  }
}
.sidebar {
  /* width: 18.5rem; */
}
.sidebar_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.main {
  height: 100%;
  background: #fafafb;
}

.new_conversion_div {
  margin-left: 8px;
}
.new_conversion_main {
  cursor: pointer;
  border: 1px solid #44444f;
  background: #4aa181;
  border-radius: 10px;
  width: 100%;
  padding: 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 0.5rem;
}
.notes {
  margin: 0.6rem 0;
  padding: 0.6rem 0rem;
  overflow-y: auto;
}
.List {
  color: white !important;
  text-decoration: none !important;
  padding: 0.5rem 0.5rem;
  cursor: pointer;
}
.selectedList {
  background: #292932;
  border-radius: 10px;
}
.edit_icon {
  margin-left: 7px;
  cursor: pointer;
}
.nav-link {
  color: #4aa181 !important;
}

.notes__name_main {
  display: flex;
  justify-content: space-between;
}
.notes_img {
  margin-right: 7px;
  width: 1rem;
}

.btn-primary {
  float: right !important;
}

.Home_logo {
  width: 2.6rem;
  height: 2.8rem;
}
.logo_main {
  display: block;
  position: absolute;
  right: 3rem;
}
.Home_content_main {
  /* height: 700px; */
  /* width: 164%; */
  margin-top: 1.4rem;
  background-color: #fafafb;
  border-radius: 23px;
}
.conversion_title {
  font-weight: 550;
  font-size: 13px;
  line-height: 15px;
  color: #fafafb;
}
.modal-dialog {
  /* max-width: 35% !important; */
}
.home_content {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 170px);
}
.home_content_heading {
  font-weight: 600 !important;
  color: #171725;
  letter-spacing: 0.1px;
  font-size: 24px;
  line-height: 36px !important;
}
.upload_title {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #44444f;
}
.upload_csv_file_main {
  margin-top: 1.5rem;
  background-color: #ffffff;
  border: 1px dashed #92929d;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  height: 100%;
}

.upload_error {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 8px;
  color: #000000;
}
.template_csv_main {
  display: flex;
  width: 49%;
}
.template_csv {
  background-color: #ffffff;
  border: 1px dashed #92929d;
  border-radius: 20px;
  width: 100%;
}
.template_csv_heading_main {
  /* width: 24rem; */
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 15px;
  justify-content: space-between;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}
.template_heading {
  font-size: 12px;
  color: #44444f;
  margin: 0rem 0.8rem;
  font-weight: 600;
}
.file_size {
  font-size: 12px;
  color: #92929d;
  font-weight: 600;
}
.template_upload_file_main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 1rem;
}
.downloading_img_main {
  /* width: 55%; */
  margin-right: 0.5rem;
}
.downloading_img {
  float: right;
  padding: 0rem 1.1rem !important;
  height: 42px;
  background-color: #f1f1f5 !important;
  color: black !important;
  font-weight: 550 !important;
  font-size: 12px !important;
  border: none !important;
  width: 10rem;
  /* margin-left: 1rem; */
}
.upload_file_convert_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4aa181 !important;
  border-radius: 10px !important;
  font-size: 12px !important;
  color: #ffffff !important;
  padding: 0.5rem 1.1rem !important;
  margin-right: 0.5rem;
  height: 52px !important;
  width: 263px !important;
  font-weight: 600 !important;
  cursor: pointer;
  &:disabled {
    opacity: 0.65;
  }
}
.upload_file_convert_btn_online {
  background-color: #92929d !important;
}
.disabled {
  opacity: 0.65;
}
.pointer-none {
  pointer-events: none;
}
.file_convert_button_main {
  /* width: 61%; */
}
.data_template_main {
  border: 5px solid #ffffff;
  background-color: #ffffff;
  box-shadow: 0px 5px 20px rgba(146, 146, 157, 0.05);
  border-radius: 10px;
  margin-top: 1rem;
  /* height: 500px; */

  /* padding: 0.5rem 0.9rem; */
  /* overflow-y: scroll; */
}
.data_template_heading {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1px;
  color: #44444f;
}
.table_heading {
  font-size: 11px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: #44444f;
}
.table_body {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.2px;
  color: #92929d;
}
.table_heading th {
  vertical-align: middle;
}

.transformation-buttons-wrapper {
  display: flex;
  width: 100%;
  gap: 8px;
}

@media (max-width: 1024px) {
  .transformation-buttons-wrapper {
    flex-direction: column;
  }
  .transformation-buttons-wrapper label,
  .transformation-buttons-wrapper button {
    width: 100% !important;
    margin: 0 !important;
  }
}

.transformation-buttons-wrapper label,
.transformation-buttons-wrapper button {
  position: relative;
  height: 48px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  width: 300px;
  padding: 8px !important;
}

label.upload_csv_btn {
  display: flex;
  flex-direction: column;
}

label.upload_csv_btn p {
  margin-bottom: 0 !important;
  position: unset !important;
}

.upload_csv_btn {
  background: #4aa181;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  justify-content: center;
  line-height: 15px;
  width: 263px;
  color: #ffffff;
}
.hyper-text {
  font-size: 14px;
  color: #92929d;
  text-decoration: underline;
  cursor: pointer;
}
.reload_modal_body_para {
  /* font-family: 'Inter'; */
  font-size: 22px;
  line-height: 29px;
  text-align: center;
  color: #92929d;
}
.btn-dark {
  background: #4aa181 !important;
  border-color: #4aa181 !important;
  border-radius: 10px !important;
  padding: 0.5rem 2.5rem !important;
  margin-bottom: 1rem;
}
.reload_btn_main {
  display: flex;
  justify-content: center;
}
.notes_logout_main {
  height: calc(100vh - 128px);
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
.all_history_heading {
  font-family: 'Inter';
  font-size: 12px;
  letter-spacing: 0.1px;
  color: #4aa181;
  text-align: center;
  margin-top: 1rem;
}
.update_icon {
  margin: 0rem 0.6rem;
}
.updates_faq_div {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.maxSizeError {
  border: 1px dashed #ff4d4d !important;
}
.labelError {
  color: #ff4d4d !important;
}
.tableData {
  overflow-x: scroll;
  /* max-height: 90%; */
  overflow-y: scroll;
  min-height: 100px;
}
.table_heading {
  background: #fafafb;
  border-radius: 10px;
}
.nav-tabs {
  --bs-nav-tabs-link-active-bg: #fafafb !important;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fafafb !important;
  --bs-nav-tabs-border-width: 0px !important;
}
.nav-link.active {
  font-weight: 650;
}
.hideSideBar {
  display: none;
}
.exportBtn {
  cursor: pointer;
  background: #4aa181 !important;
  border-radius: 10px !important;
  color: #ffffff !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  border-color: #4aa181 !important;
  line-height: 15px !important;
  width: 186px !important;
  height: 48px !important;
}
.appendedTxt {
  font-weight: 700;
  font-size: 38px;
  line-height: 48px;
  text-align: center;
  color: #4aa181;
  margin-bottom: 0rem !important;
  margin-top: -1.5rem !important;
}
.primary-bg {
  background-color: #4aa181da;
}

.okBtn {
  width: 263px !important;
}

.uploadField {
  display: none !important;
}
.csvHeader {
  max-width: 66;
  display: flex;
  align-items: center;
  margin-right: 0.7rem;
}
.tableMainInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.delImg {
  cursor: 'pointer';
}
.convertedRow {
  color: '#44444F';
}
.reloadTitle {
  color: #4aa181 !important;
}
.appendedModalBody {
  padding: 1rem 0rem !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.status-done {
  color: #4aa181;
}

.status-success {
  color: #4aa181;
}

.status-error {
  color: red;
}

.status-failure {
  color: red;
}

.status-working {
  color: black;
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: white; /* Background color to ensure it overlays content beneath it */
  z-index: 99; /* To ensure it overlays the rows when scrolling */
}
.button-div {
  display: flex;
  gap: 7px;
  > button {
    background-color: light;
    height: 48px;
    width: max-content;
  }
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-button {
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  outline: none;
}

.custom-button.disabled {
  background-color: #c0c0c0;
  cursor: not-allowed;
}

#saveButton {
  background-color: #4caf50;
}

#saveButton:hover {
  background-color: #45a049;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

#deleteButton {
  background-color: #f44336;
}

#deleteButton:hover {
  background-color: #da190b;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1180px) {
  .template_csv_heading_main {
    /* width: 17rem; */
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
  }
  .button-div > button {
    width: unset;
  }
}

@media (max-width: 885px) {
  .header {
    width: auto;
  }
}

@media (max-width: 1080px) {
  .template_csv_heading_main {
    width: auto;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
  }
  .template_csv {
    width: 100%;
  }
  .template_csv_main {
    width: 100%;
  }
}
@media (max-width: 660px) {
  .header {
    padding: 0 20px;
  }
}

@media (max-width: 722px) {
  .header_open,
  .header_close {
    margin-left: 0rem;
  }
  .logo_main {
    display: none;
  }
  .nav-tabs {
    margin-left: 4rem;
  }
}
.menuIcon {
  position: absolute;
  top: 0.6rem;
  font-size: 1.5rem;
  left: 1rem;
}
.closeImg {
  position: absolute;
  left: 16rem;
  /* left: 17rem; */
  top: 0.8rem;
}
@media (max-width: 350px) {
  .btn-light {
    font-size: 0.6rem !important;
  }
  .file_size {
    font-size: 10px !important;
    font-weight: 600;
  }
}
@media (max-width: 800px) {
  .modal-dialog {
    /* max-width: 59% !important; */
  }
}
@media (max-width: 580px) {
  .modal-dialog {
    /* max-width: 73% !important; */
  }
}
@media (max-width: 952px) {
  .Home_content_main {
    height: 100%;
  }
}
@media (max-width: 400px) {
  .reload_modal_body_para {
    font-size: 18px;
  }
  .btn-dark {
    width: 9rem;
  }
  .modal-title {
    font-size: 1rem !important;
  }
}

@media (max-width: 1235px) {
  .upload_file_convert_btn {
    width: 10rem !important;
  }
  .downloading_img {
    width: 8rem;
  }
}
.btn-light {
  width: 235px;
  height: 48px;
}

.green-text-12px {
  color: #4aa181;
  font-size: 12px;
  font-weight: 600;
}

.MuiAccordion-root {
  width: 100%;
}
.MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
  border-radius: 8px !important;
}
.MuiAccordion-root:before {
  display: none;
}
.MuiAccordionSummary-root.Mui-expanded {
  min-height: unset !important;
}
.MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0 !important;
}
.MuiAccordionDetails-root {
  flex-direction: column;
}

.drop-down-container {
  background: #fafafb;
  border-radius: 8px;
  border: 1px solid #e2e2ea;
  /* height: 213px; */
  overflow-y: scroll;
  overflow-x: hidden;
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 16px;
  padding-bottom: 16px;
  position: relative;
  /* top: 585px; */
  width: 100%;
  display: flex;
  /* gap: 1px; */
  flex-wrap: wrap;
  > div {
    width: 49%;
    border-bottom: 1px solid #e2e2ea;
    &:nth-child(2n + 1) {
      border-right: 1px solid #e2e2ea;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}
.title {
  font-size: 12px;
  font-weight: 600;
}
.description {
  color: #44444f;
  font-size: 14px;
  font-weight: 400;
}

.drop-down-container-sm {
  background: #fafafb;
  border-radius: 8px;
  border: 1px solid #e2e2ea;
  /* height: 213px; */
  overflow-y: scroll;
  padding: 16px;
  /* position: absolute; */
  /* margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px; */
  /* position: absolute; */
  /* top: 550px; */
  width: 100%;
}

.margin {
  margin-top: -16px;
}
.description-data {
  font-size: 10px;
  color: #44444f;
  margin-top: -30px;
  font-weight: 400;
  width: 90%;
}

.rotate {
  transform: rotate(180deg);
  margin-bottom: 30px;
}

.drop-down-container .d-flex:hover {
  cursor: pointer;
  background-color: #f0f0f0;
  border: 1px solid #e2e2ea;
  border-radius: 5px;
}
.img-container {
  background-color: white;
  padding: 10px;
  border: 1px solid #e2e2ea;
  border-radius: 8px;
  height: 55px;
  width: 56px;
}

/* Style the circular container */
.circle-container {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  overflow: hidden;
}

.circle-container img {
  width: 100%;
  height: auto;
  display: block;
}
.sl-preview-cta:hover .sl-preview-cta-ripple {
  transition: all 1s cubic-bezier(0, 0, 0.2, 1);
  inset: -0.75em !important;
  opacity: 0 !important;
}
.sl-preview-cta:hover .sl-preview-cta-ripple-shadow {
  opacity: 0.125 !important;
}

@media only screen and (max-width: 1500px) {
  .drop-down-container {
    flex-direction: column;
    flex-wrap: nowrap;
    > div {
      width: 100%;
      border-right: none !important;
      padding-bottom: 5px;
    }
  }
}
.with-info {
  position: relative;

  &:hover {
    .pop-info {
      display: block;
      position: absolute;
      bottom: 100;
      right: 200;
      background-color: #00000080;
      width: 250px;
      padding: 10px;
      border-radius: 7px;

      p {
        font-size: 12px;
        margin: 0;
        color: white;
      }
    }
  }

  .pop-info {
    display: none;
  }
}

.checkbox-custom {
  span {
    font-size: 14px;
    color: #92929d;
  }

  .MuiIconButton-label {
    color: #4aa181;
  }
}

/* Add these styles for the export dropdown */
.export-dropdown-menu {
  min-width: 160px;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.export-dropdown-menu .dropdown-item {
  padding: 0.5rem 1rem;
  font-size: 12px;
  font-weight: 600;
  color: #44444f;
  cursor: pointer;
}

.export-dropdown-menu .dropdown-item:hover {
  background-color: #f8f9fa;
  color: #4aa181;
}

.export-dropdown-menu .dropdown-item i {
  width: 20px;
  text-align: center;
}

/* Update the existing exportBtn class */
.exportBtn {
  cursor: pointer;
  background: #4aa181 !important;
  border-radius: 10px !important;
  color: #ffffff !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  border-color: #4aa181 !important;
  line-height: 15px !important;
  width: 186px !important;
  height: 48px !important;
}

.exportBtn:hover,
.exportBtn:focus,
.exportBtn:active {
  background: #3d8b6d !important;
  border-color: #3d8b6d !important;
  box-shadow: none !important;
}

/* Add styles for the dropdown toggle */
.exportBtn::after {
  margin-left: 0.5em;
  vertical-align: middle;
}
