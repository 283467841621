.authLayoutWrapper{
    position: relative;
    background-color: #4AA181;
    height: 100vh;
}
.authImageLayoutLogo{
  display: none;
}
.datateraai{
    color: white;
    font-weight: 800;
    font-size: 2.5rem;
}
.center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .authImage {
    display: block; /* Default: Show the image */
    height: 100vh;
    width: 100%;
  }
  
  @media (max-width: 899px) {
    .authImage {
      display: none; /* Hide the image on screens smaller than 780px */
    }
    .signin{
      margin-top: 0px;
    }
    .authImageLayoutLogo{
      display: block !important;
      margin: auto;
    }
    .authLayoutWrapper {
        height: 0vh;
    }
    .authImageLayout{
        width: 0px !important;
        height: 0px !important;
    }
    .authImageLayoutLogo{
      width: 70px !important;
      height: 70px !important;
    }
    .center {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, 0%);
    }
    .datateraai{
     display: none ;
  }
  }