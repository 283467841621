.your_plan {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  border: 1px solid #d9d9e3;
}

.title {
  /* display: flex;
  justify-content: flex-start;
  align-items: flex-start; */
  gap: 20px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 10px;
  position: relative
}
.plan-title{
  text-align: center !important;
}

.p_color {
  color: #4aa181;
}

.modal_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
 
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  background-color: #4aa181 !important;
  height: object-fit;
  margin-bottom: 20px;
  border-radius: 10px;
  font-weight: 600;
  color: white !important;
  cursor: pointer;
}

.freebtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  background-color: #c5c5d2a3 !important;
  height: object-fit;
  margin-bottom: 30px;
  font-weight: 600;
  border-radius: 10px;
  color: #3a3b3e !important;
}

.description {
  display: flex;
  gap: 10px;
  margin-top: 5px;
  margin-left: 10px;
}

.btn1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  background-color: #4aa181 !important;
  height: object-fit;
  margin-bottom: 20px;
  border-radius: 10px;
  font-weight: 600;
  color: white !important;
}

.manage_subscription_btn {
  font-size: 18px;
  cursor: pointer !important;
}

.manage_subscription_btn:hover {
  text-decoration: underline;
}

.yearly_btn {
  margin-top: 10px;
  font-size: 15px;
  background: #4aa181;
  color: white;
  border-radius: 20px;
  padding: 3px 5px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.yearly_btn_parent {
  display: flex;
  justify-content: center;
  width: 100%;
  height: fit-content;
  margin-top: -12px;
  margin-bottom: 9px;
}

.preLoader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title_row {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.yearly_btn:hover {
  text-decoration: underline;
}

@media (max-width: 885px) {
  .your_plan {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    border: 1px solid #d9d9e3;
  }
}