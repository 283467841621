.header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.justifyEnd {
    justify-content: flex-end;
}

.menuIcon {


    font-size: 1.5rem;


}