/* Modal styling */
.upload-modal .modal-content {
  border-radius: 16px;
  border: none;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
  padding: 24px;
  background: #ffffff;
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
}

.upload-modal .modal-header {
  border-bottom: none;
  padding: 0 0 16px 0;
}

.upload-modal .modal-title {
  font-size: 24px;
  font-weight: 600;
  color: #000000;
  width: 100%;
  text-align: left;
  margin: 0;
}

.upload-modal .modal-body {
  padding: 0;
}

.upload-modal .btn-close {
  opacity: 1;
  padding: 0;
}

/* Checkbox container */
.checkbox-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

/* Checkbox groups */
.MuiFormControlLabel-root {
  margin: 0 !important;
  padding: 0 !important;
}

.MuiFormControlLabel-label {
  font-size: 16px !important;
  color: #000000 !important;
  font-weight: 500 !important;
  line-height: 1.4 !important;
}

.checkbox-description {
  font-size: 14px;
  color: #666666;
  margin-left: 32px;
  margin-top: -8px;
}

/* Common button styles */
.upload_file_convert_btn,
.transform-button {
  padding: 14px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  border: none;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: normal;
  line-height: 1;
}

/* Upload button specific styling */
.upload_file_convert_btn {
  background: #60B979;
  color: white;
  margin-bottom: 0;
  max-width: 100%;
  font-size: 16px;
}

.upload_file_convert_btn:hover:not(.disabled) {
  background: #4FA167;
}

.upload_file_convert_btn.disabled {
  background: #E2E2EA;
  cursor: not-allowed;
}

/* Transform button specific styling */
.transform-button {
  background: #8B3DFF;
  color: white;
  margin-top: 24px;
  max-width: 100%;
}

.transform-button:hover:not(:disabled) {
  background: #7B35E5;
}

.transform-button:disabled {
  background: #E2E2EA;
  cursor: not-allowed;
}

/* File formats */
.file-formats {
  color: #000000;
  font-size: 14px;
  text-align: center;
  margin: 8px 0 16px;
}

/* Hidden file input */
.uploadField {
  display: none;
}

/* Textarea section */
.textarea-section {
  position: relative;
  margin-top: 24px;
}

.textarea-label {
  background: #F1F1F5;
  border-radius: 8px;
  padding: 2px 12px;
  color: #44444F;
  font-size: 14px;
  position: absolute;
  top: -10px;
  left: 16px;
  z-index: 1;
}

.upload-textarea {
  border: 1px solid #E2E2EA;
  border-radius: 12px;
  padding: 16px;
  width: 100%;
  min-height: 180px;
  resize: vertical;
  font-size: 15px;
  line-height: 1.5;
  background: white;
}

.upload-textarea:focus {
  border-color: #60B979;
  outline: none;
}

.upload-textarea::placeholder {
  color: #666666;
}

/* File list */
.file-list {
  margin: 8px 0;
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
}

.file-item p {
  margin: 0;
  font-size: 14px;
  color: #000000;
  word-break: break-all;
  padding-right: 12px;
}

.file-item img {
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s;
  flex-shrink: 0;
}

.file-item img:hover {
  opacity: 1;
}

/* Custom checkbox styling */
.MuiCheckbox-root {
  padding: 6px !important;
}

/* Enabled checkbox */
.MuiCheckbox-root:not(.Mui-disabled) {
  color: #60B979 !important;
}

.MuiCheckbox-root.Mui-checked:not(.Mui-disabled) {
  color: #60B979 !important;
}

/* Disabled checkbox */
.MuiCheckbox-root.Mui-disabled {
  color: #C4C4CF !important;
}

.MuiCheckbox-root.Mui-checked.Mui-disabled {
  color: #C4C4CF !important;
}

/* Label styling for disabled state */
.MuiFormControlLabel-root.Mui-disabled .MuiFormControlLabel-label {
  color: #92929D !important;
}

/* Description text for disabled checkboxes */
.Mui-disabled + .checkbox-description {
  color: #92929D;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .upload-modal .modal-content {
    margin: 16px;
    padding: 20px;
  }

  .checkbox-description {
    margin-left: 28px;
  }
}

@media (max-width: 576px) {
  .upload-modal .modal-content {
    margin: 12px;
    padding: 16px;
  }

  .upload-modal .modal-title {
    font-size: 22px;
  }

  .MuiFormControlLabel-label {
    font-size: 15px !important;
  }

  .checkbox-description {
    font-size: 13px;
    margin-left: 24px;
  }

  .upload_file_convert_btn,
  .transform-button {
    padding: 12px;
    font-size: 15px;
  }
}

@media (max-width: 320px) {
  .upload-modal .modal-content {
    margin: 8px;
    padding: 12px;
  }

  .upload-modal .modal-title {
    font-size: 20px;
  }
}
