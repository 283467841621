.signin_main {
  height: calc(100vh - 100px);
  justify-content: center;
  padding: 0 20px;
}
.pic_div {
  display: flex;
  justify-content: center;
  width: 100%;
}
.signinUpper {
  height: 100%;
  display: flex;
  margin-top: -4rem;
}

@media screen and (max-width: 768px) {
  .signinUpper {
    margin-top: 0;
  }
}

.signin {
  width: 100%;
  max-width: 420px;
  margin: auto;
}

.login_heading {
  font-weight: 700;
  font-size: 40px;
  color: #4aa181;
  text-align: center;
}

.explore_future_heading {
  font-weight: 400;
  font-size: 24px;
  color: #92929d;
  text-align: center;
}

.signin_login_btn {
  background-color: #4aa181 !important;
  border-color: #4aa181 !important;
  width: 100% !important;
  border-radius: 10px !important;
  height: 48px !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}

.signin_button_main {
  display: flex;
}

.signin_with_email {
  color: #92929d;
  font-size: 12px;
  margin-top: 2rem;
  margin-bottom: 2rem !important;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid rgba(68, 68, 79, 0.1);
  line-height: 0.1em;
  margin: 10px 0 20px;
  font-weight: 400;
}

.signin_email_span {
  margin-left: 10px;
  margin-right: 10px;
  background: #fff !important;
  padding: 0 10px !important;
}

.googlelogo {
  width: 1.5rem;
  margin-right: 8px;
}

.signin_div {
  background-color: #f1f1f5;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  height: 48px !important;
}

.signin_div_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  margin-top: 3rem;
  width: 60px;
}

.eye_icon_main {
  display: flex;
  position: relative;
}
.eye_icon {
  cursor: pointer;
  position: absolute;
  right: 5%;
  top: 28%;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none !important;
}

.form-control.is-invalid {
  background-image: none !important;
}

@media screen and (max-width: 280px) {
  .explore_future_heading {
    font-weight: 400;
    font-size: 20px;
    color: #92929d;
    text-align: center;
  }

  .signin_login_btn {
    background-color: #4aa181 !important;
    border-color: #4aa181 !important;
    width: 100% !important;
    border-radius: 10px !important;
    height: 48px !important;
  }

  .signin_button_main {
    display: block !important;
  }
}

@media screen and (min-width: 281px) and (max-width: 425px) {
  .signin_login_btn {
    background-color: #4aa181 !important;
    border-color: #4aa181 !important;
    width: 100% !important;
    border-radius: 10px !important;
    height: 48px !important;
  }

  .signin_button_main {
    display: block !important;
  }
}

@media (max-width: 425px) {
  .signin_div_main {
    display: block !important;
  }

  .Login_btn {
    margin-right: 0px !important;
  }
}

@media (max-width: 425px) {
  .signin_login_btn {
    margin-bottom: 2rem;
  }
}
