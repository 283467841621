.required {
  color: red;
  margin-left: 0.2em;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.integration-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
  padding: 20px;
  background-color: transparent;
  margin-left: auto;
}

.integration-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer;

  position: relative;
}

.integration-item.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.integration-item .coming-soon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
}

.integration-item:hover {
  transform: translateY(-5px);
}

.icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.name {
  font-size: 16px;
  color: #333;
  text-align: center;
}

.body {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.heading {
  font-size: 30px;
  color: #4aa181;
  /* width: calc(100% - 250px); */
  padding: 20px;
}

.heading_open,
  .integration-container_open {
    margin-left: 0rem;
  }
  .heading_close,
  .integration-container_close {
    margin-left: 0rem;
  }

@media (min-width: 722px) {
  .heading_open,
  .integration-container_open {
    margin-left: 16.5rem;
  }
  .heading_close,
  .integration-container_close {
    margin-left: 80px;
  }
}

.sliding-pane-title {
  font-size: 24px;
  color: #333;
  animation: slideIn 0.5s ease forwards;
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.title-with-logo {
  display: flex;
  align-items: center;
  font-size: 24px;
}

.logo-circle {
  background-color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  overflow: hidden;
}

.logo-image {
  max-width: 70%;
  max-height: 70%;
}

.pane-content {
  padding: 15px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  margin-top: 15px;
}

.pane-heading {
  font-size: 16px;
  color: #333;
  font-weight: bold;
  margin-bottom: 15px;
  padding: 10px;
  background-color: #e9e9e9;
  border-radius: 4px;
}

.pane-heading.success {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: #e9ffd4;
}

.pane-heading.fail {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: #ffd5d5;
}

.pane-list {
  list-style: none;
  padding: 0;
  margin-top: 10px;
}

.pane-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.pane-list-item input[type='radio'] {
  margin-right: 10px;
}

.pane-list-item label {
  margin: 0;
  font-size: 14px;
  color: #333;
}

.form-section {
  background-color: #f7f8fa;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
}

.radio-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.radio-input {
  margin-bottom: 10px;
}

.input-field {
  border: 1px solid #dfe1e5;
  border-radius: 4px;
  padding: 10px 15px;
  margin-bottom: 15px;
  width: 100%;
}

.connect-btn {
  background-color: #0052cc;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  text-align: center;
}

.connect-btn:hover {
  background-color: #003ea0;
}

.connect-text {
  margin-bottom: 15px;
}

.pane-footer {
  position: sticky;
  bottom: 0;
  background: #fff;
  padding: 10px 20px;
}

.footer-button {
  margin-right: 10px;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.footer-button:last-child {
  background-color: #6c757d;
}

.input-group {
  position: relative;
  display: flex;
  align-items: center;
}

.input-field {
  flex: 1;
  border: 1px solid #dfe1e5;
  border-radius: 4px;
  padding: 10px 40px 10px 15px;
  margin-bottom: 15px;
  width: 100%;
}

.icon-button {
  position: absolute;
  right: 10px;
  border: none;
  background: transparent;
  cursor: pointer;
  top: 30%;
  transform: translateY(-30%);
}

.icon-button:focus {
  outline: none;
}

.pane-footer {
  display: flex;
  justify-content: flex-start;
}

.footer-button:not(:disabled):hover {
  opacity: 0.8;
}

.footer-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.status-icon {
  position: absolute;
  top: 10%;
  right: 10%;
  font-size: 1.2em;
}

.delete-button {
  padding: 5px 10px;
  border: 1px solid red;
  background-color: white;
  color: red;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.delete-button:hover {
  background-color: red;
  color: white;
}
