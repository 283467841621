.body {
    margin-left: 300px;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .closeSidebar_body {
    margin-left: 90px;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .heading {
    font-size: 30px;
    color: #4aa181;
  
    margin: 30px 0 20px 0;
  }
  
  .header {
    padding: 24px;
  }
  
  .header svg {
    position: relative;
    top: 0;
    left: 0;
  }
  
  .row {
    display: flex;
    flex-direction: column;
    gap: 2px;
    height: 40px;
  }
  
  .title {
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    color: #44444f;
    margin-bottom: 10px;
  
    flex: 1;
  }
  
  
  .value {
    font-weight: bold;
  
    font-size: 15px;
    letter-spacing: 0.8px;
    color: #4aa181;
    margin-bottom: 10px;
  
    flex: 3;
  }
  
  .div {
    flex: 3;
  }
  
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  
  .gridItem {
    background: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .value,
  .input {
    width: 100%;
  }
  
  .button {
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
   
  }
  
  .button:disabled {
    background-color: #ccc;
  }
  
  .button {
    color: #fff;
    background-color: #4aa181;
    border: 0;
    border-radius: 7px;
    padding: 5px 20px;
  }
  .border {
    color: black;
    background-color: white;
    border: 2px solid #4aa181;
    border-radius: 7px;
    padding: 5px 10px;
  }
  
  .input {
    font-weight: normal;
    color: #44444f;
    width: 300px;
    border-radius: 3px;
    border: 1px solid #4aa181;
    background-color: #fff;
    padding: 3px 10px;
    > input {
      border: 0;
      &:focus {
        outline: none;
      }
    }
  }
  
  .table {
    width: 100%;
    background-color: #fff;
    padding: 10px;
    border-radius: 7px;
    & > thead {
      display: flex;
      /* margin-bottom: 20px; */
      & > th {
        flex: 1;
        padding-bottom: 5px;
      white-space: nowrap;

        border-bottom: 1px solid #44444f50;
        &.more_space {
          flex: 3;
        }
      }
    }
  
    & > tbody {
      display: flex;
  
      & > td {
        flex: 1;
        word-break: break-all;
        padding: 5px 0;
        border-bottom: 1px solid #44444f50;
        &.more_space {
          flex: 3;
        }
      }
    }
    &.min_width {
      overflow-x: scroll;
      & > thead {
        min-width: 750px;
      }
      & > tbody {
        min-width: 750px;
      }
    }
  }
  
  @media screen and (max-width: 722px) {
    .body {
      margin: 0 20px;
    }
    .input {
      width: 270px;
    }
    .name-field{
      margin-top: 20px;
    }
   
  }
   
  @media screen and (max-width: 1022px) {
    .name-field {
      margin-top: 20px;
    }
  }
  