.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}
