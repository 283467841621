.sidebarMain {
  background-color: #1c1c24 !important;
  color: white !important;
  max-height: 100%;
  /* overflow-y: auto; */
}

.conversionTitle {
  color: #4aa181;
}

.submitBtn {
  background-color: #4aa181 !important;
  color: white !important;
  border: none !important;
}

.emailLabel {
  font-family: 'Roboto', sans-serif;
  /* font-size: calc(5px + 0.5vw); */
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.1px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0 5px 0;
}

.emailLabel u {
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.emailLabel-sm {
  font-family: 'Roboto', sans-serif;
  /* font-size: calc(10px + 0.5vw); */
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.1px;
  display: flex;
  align-items: center;
  margin: 5px 0 5px 0;
}

.emailLabel-sm u {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.view-profile-button {
  font-size: 12px;
  width: max-content;
  margin: 0 auto 15px auto;
  cursor: pointer;
  &:hover {
    scale: 1.1;
  }
}

.arrow_icon {
  width: 20px;
  height: 20px;
}

.sidebar_btns_main {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
}

.sidebar_btns_main img {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.prcing {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Pricing_modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
}

.plan {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  height: 40px;
  padding: 10px;
}

.call_component {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

@media (max-width: 1024px) {
  .call_component {
    flex-direction: column;
  }
}

.contact_info {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  gap: 5px;
  padding-left: 10px;
}

.contact {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
}

.contact_ref {
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  color: #4aa181;
  text-decoration: underline;
  padding-right: 10px;
  cursor: pointer;
}

.plan_modal .modal-dialog {
  max-width: 70%;

  padding: 0 20px;
  margin: 0 auto;
}

@media (max-width: 1024px) {
  .plan_modal .modal-dialog {
    max-width: 100%;
  }
}

.plan_modal_body.modal-body {
  padding: 0px !important;
}
.loader{
  margin:30px;
}
.dropdown2 {
  right: 30px;
  margin-top: 10px;
  margin-left: 55px;
  width: 150px;
  /* height: 190px; */
  background-color: #292932;
  color: white;
  border-radius: 10px;

  z-index: 9;
  position: fixed;
  border: 1px solid black;
}

.elements {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 5px;
}

.elements:hover {
  background-color: #1c1c24;
}
.disable {
  background-color: #1c1c24;
  cursor: not-allowed;
}

.dotsIcon {
  margin-left: 7px;
  cursor: pointer;
}

.section {
  z-index: 2;
  background-color: #1c1c24;
}

.create-ai-btn {
  background: linear-gradient(45deg, #00c853, #64dd17); /* Modern gradient */
  color: white;
  border: none;
  border-radius: 8px; /* Slightly more rounded */
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
  cursor: pointer;
  box-shadow: 0 0 15px rgba(100, 221, 23, 0.4); /* Subtle glow effect */
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.create-ai-btn:hover {
  transform: translateY(-2px) scale(1.02);
  box-shadow: 0 0 25px rgba(100, 221, 23, 0.6); /* Enhanced glow on hover */
  background: linear-gradient(
    45deg,
    #00e676,
    #76ff03
  ); /* Brighter gradient on hover */
}

.create-ai-btn:disabled {
  background: linear-gradient(
    45deg,
    #9e9e9e,
    #bdbdbd
  ); /* Muted gradient when disabled */
  cursor: not-allowed;
  box-shadow: none;
  transform: none;
  opacity: 0.7;
}

.close-btn {
  position: absolute;
  right: -0.8rem;
  top: 3rem;
  z-index: 100;
  border-radius: 50%;
  background: #0000 !important;
  border: 0;
}

.btn-div {
  width: 25px;
  height: 25px;
  display: grid;
  place-content: center;
  background: #1c1c24 !important;
  border-radius: 50%;
}

.hover-text {
  position: relative;
}

.hide-text {
  display: none;
}

.hover-text:hover .hide-text {
  display: block;
  background: white;
  z-index: 100;
}

.hover-text {
  position: relative;
}

.hide-text {
  display: none;
}
.hide-text2 {
  display: none;
}

.hover-text:hover .hide-text {
  background: black;
  z-index: 100;
  color: white;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  position: absolute;
  width: 8rem;
  right: -8rem;
}

.hidden {
  display: none;
}

.menu {
  width: 7.5rem;
  padding: 0.1rem;
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Hides overflowing content */
  text-overflow: ellipsis; /* Adds "..." at the end */
  text-align: center;
}